import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";

import home_bk_1 from "../img/home/home_bk_1.png";
import home_bk_2 from "../img/home/home_bk_2.png";
import koichi_face from "../img/home/koichi_face.jpg";


import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
import tiktok from "../img/social/tiktok.svg";
import youtube from "../img/social/youtube.svg";


// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <div
        className="full-width-image-container  margin-top-0 margin-btm-0"
        style={{
          backgroundImage: `url(${home_bk_1})`,
          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      >
        {/* <video
                  className="video-player"
                  width="100%"
                  loop
                  muted
                  autoPlay
                >
                  <source
                    src={videorobo}
                    type="video/mp4"
                  />
                </video> */}
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            position: "absolute",
          }}
        >
          < div className="has-text-centered"
            style={{
              position: "absolute",
              bottom: '300px',
              left: '100px',
              transform: "rotate(-10deg)",
              background: "linear-gradient(transparent 50%, #dce2f0 00%)"

            }}>
            <strong
              className="is-size-chosen"
              style={{
                color: 'white', fontSize: "14vW", fontSize: "14vmax", fontFamily: "Sawarabi Mincho"
              }}
            >
              <p>挑戦</p>
            </strong>
          </div >
          <div className="has-text-centered"
            style={{
              position: "absolute",
              bottom: '100px',
              right: '100px'
            }}>
            <strong
              className="is-size-koichiname"
              style={{
                color: 'white', fontSize: "5vW", fontSize: "5vmax", fontFamily: "Sawarabi Mincho"
              }}
            >
              <p>中村　弘一</p>
            </strong>
          </div >
        </div>
      </div>

      <div
        className="full-width-image-container-2 margin-top-0 margin-btm-0"
        style={{
          backgroundImage: `url(${home_bk_2})`,
          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      >
        <div
          className="full-width-image-containe"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            position: "absolute",
          }}
        >
          < div className="has-text-centered section">
            <strong
              style={{
                fontSize: "3vW", fontSize: "2.2vmax", fontFamily: "Sawarabi Mincho"
              }}
            >
              <p>
                大学時代のラクロス部創設から始まり<br />
                奨学金やスポンサーを獲得しながら<br />
                世界トッププロに挑戦中<br />
                <br />
                ずば抜けた技術や才能はなく不器用だが<br />
                誰に何を言われようと諦めなかったから今がある<br />
                <br />
                “ 道が無ければ 自分で切り拓く！”
              </p>
            </strong>
            <figure class="image is-16by9">
              <iframe class="has-ratio" width="560" height="315" src="https://www.youtube-nocookie.com/embed/m2vJ1K0DSHg?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </figure>
          </div >
        </div>
      </div>

      <div className="columns margin-btm-0">
        <div className="column is-6"
          style={{
            backgroundColor: "#C0C0C0"
          }}>
          <div className="section">
            <div className="column is-10 is-offset-1">
              <img
                className="margin-btm-0  column is-8 is-offset-2 borderRadius"
                src={koichi_face}
                alt=""
                width="100%"
                height="auto"
              />
            </div>
            <div className="has-text-centered">
              <strong
                className="is-size-2"
                style={{
                  color: 'black', fontFamily: "Sawarabi Mincho"
                }}
              >
                <p>
                  中村　弘一
                </p>
              </strong>
            </div>
          </div>
        </div>
        <div className="column is-6"
          style={{
            backgroundColor: "#A9A9A9"
          }}>
          <div className="section">
            <div className="has-text-centered">
              <strong
                className="is-size-2"
                style={{
                  color: 'black'
                }}
              >
                <p>
                  HISTORY
                </p>
              </strong>
            </div>
            <br />
            <div className="has-text-left">
              <p
                style={{
                  color: 'black',
                  fontSize: "3vW",
                  fontSize: "1.8vmax",
                  fontFamily: "Sawarabi Mincho"
                }}
              >
                ・中村学園大学ラクロス部創設<br /><br />

                ・卒業後トビタテ留学Japanの奨学金をもらい<br />　2年間のラクロス留学<br /><br />

                ・帰国後4000社にアプローチして<br />　スポンサー獲得<br /><br />

                ・北米にてトッププロへの挑戦中
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="content section margin-btm-0"
        style={{
          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`,
          backgroundColor: "#708090",
          width: "100%"
        }}
      >
        <div className="has-text-centered">
          <strong
            className="is-size-2"
            style={{
              color: 'black'
            }}
          >
            <p>
              SNS
            </p>
          </strong>
        </div>
        <div class="buttons has-addons is-centered">
          <a class="button is-large is-responsive" title="facebook" href="https://www.facebook.com/kouichi.nakamura.169">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a class="button is-large is-responsive" title="facebook" href="https://twitter.com/koubp1227">
            <img
              src={twitter}
              alt="Twitter"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a class="button is-large is-responsive" title="facebook" href="https://www.youtube.com/channel/UCRAYDMkzw3RIhldWUtLOSjw">
            <img
              src={youtube}
              alt="YouTube"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a class="button is-large is-responsive" title="facebook" href="https://tiktok.com">
            <img
              src={tiktok}
              alt="TickTok"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
